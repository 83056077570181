<template>
  <b-button
    variant="blue-button"
    class="blue-button blue-button-search-action-searcher"
    @click="sendFilters()"
    :disabled="total_results < 1"
  >
    <div class="d-flex justify-content-center align-items-center">
      <b-spinner v-if="loading" small type="grow" class="mr-1"></b-spinner>
      <p class="mb-0">{{text}}</p>
    </div>
  </b-button>
</template>

<script>
import {
  BButton, BSpinner
} from 'bootstrap-vue'

export default {
  name: 'ButtonSubmitFilters',
  props: {
    searched: {
      type: Boolean,
      default: true
    },
    total_results: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    }
  },
  components: {
    BButton,
    BSpinner
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    sendFilters() {
      this.$emit('sendFilters')
      this.$emit('closeSideBar')
    }
  },
}
</script>
<style lang="scss">
.blue-button-search-action-searcher {
  height: 41.5px;
  width: 100%;
  @media(min-width: 768px) {
    margin: 0.7em 0 1.7em 0.8em;
    width: auto;
  }
}
</style>